
import pattern from '~/mixins/pattern.js';
export default {
    mixins: [pattern],
    data() {
        return {
            height : null,
            ean : ''
        }
    },
    computed : {
        width(){
            return this.$store.state.scroll.wW;
        },
        isWebpSupported(){
            return this.$store.state.layout.isWebpSupported;
        },
        isMobile(){
            return this.$store.state.layout.isMobile;
        },
    },
    mounted() {
        this.height = this.$el.clientHeight;
    },
    props : ['blok'],
    methods: {
        submit(){
            if(this.ean != null && this.ean != ''){
                this.$router.push({ path: this.$__url("/products"),query: {s: `EAN:${this.ean}`, categories: 'spare-parts'}, })
            }
        },
        info(){
            this.$dialogSpareParts(this.blok).then(res => {
                res.close();
            }).catch(err => {
                return false;
            })
        }
    },
}
